import { ILocation } from "state/types/toc-management-slice.type";

export const PAC_TYPE = {
  IRF: "IRF",
  SNF: "SNF",
  HH: "HH",
  MPT: "M-PT",
  OPT: "OPT",
  HOME_SERVICE: "Home w/No services",
  ACUTE: "Acute",
};

export const NOTE_LIMIT = 2000;

export const ACUTE_LOS_ERROR = "Acute LOS must be between 1 and 50";
export const FACILITY_ERROR = "Select a provider for all the services";
export const FACILITY_AND_HOME_ERROR = "Add at least 1 more service or select Home w/No services";
export const LOS_VISIT_ERROR = "LOS/Visits must be between 1";
export const LOS_ERROR = "LOS/Visits should be greater than 0";
export const EMPTY_LOS_ERROR = "Enter LOS/Visits";

export const TOC_LISTING_HEADERS = [
  {
    name: "Name",
  },
  {
    name: "Created",
  },
  {
    name: "Status",
  },
  {
    name: "Approved",
  },
  {
    name: "",
  },
];

export const TOC_ITEMS: Record<string, ILocation> = {
  irf: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.IRF,
    isProviderValid: true,
    isLosValid: true,
  },
  snf: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.SNF,
    isProviderValid: true,
    isLosValid: true,
  },
  hh: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.HH,
    isProviderValid: true,
    isLosValid: true,
  },
  mpt: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.MPT,
    isProviderValid: true,
    isLosValid: true,
  },
  opt: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.OPT,
    isProviderValid: true,
    isLosValid: true,
  },
};

export enum TocStatusEnum {
  NEW = "New",
  PENDING = "Pending",
  APPROVED = "Approved",
  CANCELLED = "Cancelled",
}

export const LOS_LIMIT: Record<string, number> = {
  IRF: 90,
  SNF: 90,
  HH: 50,
  "M-PT": 50,
  OPT: 50,
  EPISODE: 50,
};

export enum RevisionReasonModalEnum {
  APPROVED_REVISION,
  PENDING_REVISION,
}

export const cancelSubheading = "Are you sure, you want to Cancel?";
export const resetSubheading = "Are you sure, you want to Reset?";
export const submitSubheading = "Are you sure, you want to Submit?";
export const tocTitle = "Care Plan";

export enum RiskLevelEnum {
  HIGH = "high",
  STANDARD = "standard",
}

export enum PlannedUnplannedEnum {
  PLANNED = "PLANNED",
  UNPLANNED = "UNPLANNED",
}

export enum IntakeTypeEnum {
  NEW = "new",
  OLD = "old",
}
